

.work-container{
    transition: all 0.3 linear;
}

.project-heading{
    
    text-align: center;
    padding: 1rem;
    padding-bottom: 1.5rem;
    background-color: #bed8ec ;
    text-decoration: underline;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: medium;
 /*    font-style: italic; */

}  


    
.project-container{

padding-top: 1rem;
padding-bottom: 1rem;  
padding-left: 1rem; 
padding-right: 1rem; 
    display: grid;

    grid-template-columns: repeat(5, 1fr);
    grid-gap: 25px;
    background-color: #bed8ec ;
}

.project-card{
    height: 60vh;
    flex-wrap: wrap;
    justify-content: center;
    background: #025065  ;
 
    padding-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0 4rem 0 4rem ;

    position: relative;
    box-shadow: 30px 40px 40px rgba(0, 0, 0, 0.5);
/*     display: flex;
    justify-content: space-between;
    flex-direction: column; */
}

.project-card:hover{
    background: #24a6ca  ;
        padding: 1.2rem 0.5rem; 
         transition: 1s;
         cursor: default;
     } 
 .project-card p{
        font-size: 1.0 rem;
        color: rgb(184, 184, 225);  
        font-family:Verdana, Geneva, Tahoma, sans-serif ;
        font-weight: 400;
        font-size: 0.9rem;
 
     }
.project-card:hover p{
        font-size: 1.0 rem;
        color: rgb(0, 0, 0);
        font-family: "Serif", Georgia ; 
        font-weight: 600;
     }

.project-card .project_img{
    position:absolute;
    height: 25%; 
    top: -2rem; 
    width: 50%;
    left:25%;
 
}

 .project-card:hover img{
    border-radius: 50%; 
    top: -1.5rem;
 }

 

 .project-card:hover .Read_More{

    font-size: 1.0 rem;
   color: blue; 
    font-family: "Serif", Georgia ; 
    font-weight: 300;
 }



.project-title{
   margin-top: 30%; 
    text-align: center;
    color: rgb(170, 214, 219);
}

.project-details p {
    position:absolute;
    top:35%;
    padding: 0.5rem; 
    font-size: 1.0 rem;
    text-align: justify;  

text-align: left;

    font-family:Verdana, Geneva, Tahoma, sans-serif
}



.pros-btn .Read_More {
    position:absolute;
    height: 25%; 
    bottom: -5rem; 
    width: 50%;
    left:25%;

    font-family: Georgia, 'Times New Roman', Times, serif;
    text-decoration-line: underline;
        font-size: 1rem;
        font-weight: 400;
        text-align: center;
        justify-items: center;
        justify-content: center; 
        color: rgb(0, 255, 255);
    }

.project-card:hover .Read_More {
    display:block;
    transition: 1s;
    cursor: pointer;
    color: blue;
    font-size: 1rem;
  } 


@media screen and (max-width: 740px) {
    .project-container{
        max-width: 90%;
        margin: auto;
        grid-template-columns: 1fr;
    }
    .project-card{
margin-bottom: 25px;
      } 
}