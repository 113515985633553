.footer{
    width: 100%;
/*     padding: 1rem 0; */
/*     background-color: #c5c5d4;  */
background-color:  #8a97a5 ;
}

.foter-container{
    max-width: 1140px ;
   margin: auto; 

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
}

.left{
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;

}

.location{
    display: flex;
    margin-bottom: 0.8rem;
}

.location p{
    line-height: 30px;   
}

.right{
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

h5{
    font-size: 1.2rem;
    padding-bottom: 0.7rem;
}

p{
    font-size: 1rem;
}

@media screen and (max-width: 640px ){
    .foter-container{
        grid-template-columns: 1fr ;
    }
}

.footer_copyright{

text-align: center;
border-top: 2px solid #dee7f1 ; 
padding: .5rem 0 ;

}