
.about_container{
    width: 90%;
    display: grid;
    grid-template-columns: 30% 30% 30%;
    gap: 10%;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    background-color: #bed8ec ;
}

.mision_content , .vision_content , .joinlegands_content{
    display: grid;
    text-align: justify;
    margin-left: 10%;
    border-radius: 10%;
/*   border-radius: 10px 10px 10px 10px;   */
    background-color: #f4f4f4 ; 
    perspective: 100em;
    box-shadow: 30px 40px 40px rgba(0, 0, 0, 0.5);
}

.mision_content:hover , .vision_content:hover {  
    transform:rotateY(-5deg) ;
/*     background-color: blue; */
    background-color: rgb(116, 243, 246);
    transition: 5s;
    text-align: justify;

}

.mision_content:hover h1, .vision_content:hover  h1{  
    text-decoration-line: underline;
}


.joinlegands_content , .mision_content , .vision_content {
    background-color: rgb(76, 211, 218);
    transition: 5s;
    box-shadow: 30px 40px 40px rgba(0, 0, 0, 0.5);
}

.joinlegands_content:hover{
    background-color:rgb(168, 211, 246);
    transition: 1s;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 30px 40px 40px rgba(0, 0, 0, 0.5);
}
.joinlegands_content:hover h1{
    text-decoration-line: underline;
}



.mision_content:hover{
    background-color: rgb(217, 211, 240);
    transition: 1s;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 30px 40px 40px rgba(0, 0, 0, 0.5);
}


.vision_content:hover{
    background-color:  rgb(169, 206, 251);
    transition: 1s;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 30px 40px 40px rgba(0, 0, 0, 0.5);
}

.joinlegands_content h1{
    padding-top: 0;
    text-align: center;
}

.mision_content   h1{
    padding-top: 0;
    text-align: center;
   
}

.vision_content   h1{
    padding-top: 0;
    text-align: center;
   
}

.joinlegands_content , .mision_content , .vision_content  p{

    padding: 0.5rem;
    font-size: 1.0 rem;
    text-align: justify;
    font-weight: 500;

}

@media screen and (max-width: 740px) {
    .hero{
        max-width: 100%;
        margin: auto;
        grid-template-columns: 1fr;
    }
    .mask{
        width: 100%;
        margin-inline: auto;
    }
    .about_container{
        grid-template-columns: 1fr;
        gap: 4rem;
    }
}
