
.service_image{
   border-radius: 50%;    
    overflow: hidden;
    transform: skew(-15deg); 
    transition: 1s;
    display: flex;
width: 70%;
    height: 300px;
    margin-left: 100px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.service_image:hover:nth-child(2){
    transform: skew(-30deg);
}    

.service_image:hover{
    transform: skew(0);
}


.service_image1{
    border-radius: 50%;    
     overflow: hidden;
     transform: skew(-15deg); 
     transition: 1s;
     display: flex;
 width: 70%;
     height: 300px;
margin-left: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
 }
 
 
 .service_image1:hover{
     transform: skew(0);
 }



.service_content  h1{
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
}

.service_content  p{
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding-right: 2rem;
    padding-left: 2rem;
}

.service_content:hover  p{
    font-family: "Serif", Georgia ; 
    color: rgb(51, 17, 20);
    font-size:larger
}

.service_content:hover{
 /*    transform: skew(5deg);  */ 
    transition: 5s;
    background-color: rgb(116, 243, 246);
    border-radius: 20%;
/*     border-top-left-radius: 20%;
    border-bottom-left-radius: 20%;
    border-bottom-right-radius: 20%;
    border-top-right-radius: 20%; */
}


.serice_container{
    display: grid;
    grid-template-columns: 40% 50%;
    gap: 5%;
}

.serice_container1{
    display: grid;
    grid-template-columns: 50% 40% ;
    gap: 5%;
}

.service_content1:hover{
/*     transform: skew(-5deg);  */ 
    transition: 2s;
    background-color: rgb(116, 243, 246);
    border-radius: 20%;
/*     border-top-left-radius: 20%;
    border-bottom-left-radius: 20%;
    border-bottom-right-radius: 20%;
    border-top-right-radius: 20%; */
}

.service_content1:hover  p{
    font-family: "Serif", Georgia ; 
    color: rgb(51, 17, 20);
    font-size:larger
}

.service_content1  h1{
    margin-bottom: 2rem;
    margin-top: 2rem;
    text-align: center;
}

.service_content1  p{

    margin-bottom: 1rem;
    margin-top: 1rem;
    margin-left: 50px;
    font-family: "Serif", Georgia ; 
}

/* Media Queries (medium screens) */
@media screen and (max-width:1024px){
.service_image{
    width: 60%;
    }
.service_image1{
        width: 60%;
    }    
.service_content  h1{  
    margin-bottom: 1.2rem;
 
 }
 .service_content1  h1{  

    margin-bottom: 1.2rem;
 }

 .serice_container , .serice_container1{
    grid-template-columns: 1fr;
    gap: 3rem;
    
 }

 .service_image ,  .service_image1 {
    grid-row: 1;
 }


}


/* Media Queries (small screens) */
@media screen and (max-width:740px){
    .service_image{
        width: 80%;
        margin-inline: auto;
        }
    .service_image1{
            width: 100%;
            margin-inline: auto;
        }  
        .serice_container , .serice_container1{
            grid-template-columns: 1fr;
            gap: 2rem;
            
         }
}