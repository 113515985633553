.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem ;
    position: fixed;
    width: 100%;

height: 40px; 
    z-index: 10;
}

.header .logo{
  border-radius: 50%; 

margin-left: 50px;
margin-top: 40px;
}


.header-bg {
   background-color: #bed8ec   ; 
   height: 75px; 
  transition: 0.5s;
}

.header-bg .logo{
  border-radius: 50%; 
margin-left: 50px;
margin-top: 0;
}



.nav-menu{
  display: flex;  
} 



.active{
  color: blue ; 
  text-decoration-line: underline;
/*   font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
/*   font-family:Verdana, Geneva, Tahoma, sans-serif; */

}

.active::after{
  content: "";
  display: block; 
  width: 1.2rem;
  height: 1.2rem;
/*   background: #bed8ec  ;  */
  position: absolute; 
  transform: rotate(45deg);
  margin-top: 1.2rem;

} 

.nav-menu a{
     transition: 0.8s;
}  
.nav-menu a:hover{
  color: rgb(160, 19, 12);
}



.nav-menu li{
  padding: 0 1rem; 
}

.nav-menu li a{
    font-size: 1.2rem;
    font-weight: 500; 
  }

.hamburger{
  display: none;
}

  @media screen and (max-width: 1040px) {
    .nav-menu{
      position:absolute;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 80vh;
      top:100%;
      right: 0;
      flex-direction: column;
      background-color: rgba(219, 229, 235, 0.8);
    left:-100%;
    transition: 0.5s;



    z-index: -3;
    }
    
    .nav-menu.active{
      left:0;
 

    }

.nav-menu a{
 /*      transition: 0.8s; */
 animation: navAnimation 600ms ease  forwards ;
 transform: rotateX('90deg');
 opacity: 1; 
 perspective: 400px;
    }  

 .nav-menu a:hover{

   color: rgb(160, 19, 12);
   background-color: #0cbdea;
  }

    .active{
      color: blue;
    }
    
    .active::after{
      display: none;   
    }

    .logo{
      border-radius: 50%;
      padding: 1.5rem;
    
    }

    .nav-menu li{
      height: 3rem;
      width: 25%; 
      box-shadow: 1rem 1rem 50rem rgba(10, 255, 255, 2);  
      padding: .08rem ;  
    }

    .nav-menu li a{
      background-color: rgb(148, 245, 245);
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      text-align: center;
      text-size-adjust: auto;
      font-size: 2.5rem;
      /*       padding:1rem 5rem 1rem 3rem ; */

    }
 
    .hamburger{
      display:initial;
    }
 
  }