.mask{
    width: 100%;
    height: 50vh;
    position: relative;
}

.intro-img1{
    width: 100%;
    height: 500px ; 
    background-size: cover;
    background-position: center;
    object-fit:fill;

    filter:saturate(1) ;
    transition:brightness(1) ;
}

.mask:hover .intro-img{
    
    filter:invert(0.25) ;
    transition:brightness(0.50) ;  
}

.mask::after{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
   background-color: #03070f ;
    opacity: 0;
} 

.hero1 {
    height: 100%;
    width: 100%; 
    background-color: #bed8ec ;
}



.hero1 .content {
    position: absolute ;
    transform: translate(-50% , -50%) ; 
    top: 30%;
    left: 50%;
    text-align: center;

}

.hero1 .content h2 {
    font-size: 2.5rem;
    font-weight: 400;
    padding: 0.6rem 0 1rem;
   text-transform: uppercase; 
}

.hero1 .content h4 {
    font-size: 1.5rem;
    font-weight: 400;
    padding: 0.6rem 0 1.5rem;
    
}

.content .btn{
    margin: 1rem 0.2rem;
}



@media screen and (max-width: 740px) {
    .hero{
        max-width: 90%;
      margin: auto; 
        grid-template-columns: 1fr;
    }
    .mask{
        width: 90%;
        margin-inline: auto;

    }
    .content{

        grid-template-columns: 1fr;
        gap: 4rem;
    }

    .intro-img1{
        width: 100%;
        height: 300px ; 
        background-size: cover;
        background-position: center;
        object-fit:fill;
    
        filter:saturate(1) ;
        transition:brightness(1) ;
    }

    .hero1 .content h2 {

        font-family: Verdana, Geneva, Tahoma, sans-serif;
        font-size: 1.2rem;
    
      font-weight: 400;  
      padding: 0 0 1rem;   
        text-transform: 
         uppercase;  
    }
    
    .hero1 .content h4 {
        font-size: 0.7rem;
        font-weight: 400;
         padding: 0  0 2rem; 
        
    }
}