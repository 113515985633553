
.main_header{
    width: 100%;
    height: 75vh;
    display: grid;
    place-items: center;
padding: 3rem ; 
background-color: #bed8ec ;

}


