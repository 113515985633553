@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');


*{
  box-sizing: border-box;
  margin: 0;
  padding:0 ;
  font-family: 'Outfit', sans-serif;
}

:root{
  --primary-hue: 200;
  --grey-hue:240 ;
  --color-primary:hsl(var(--primary-hue) , 100%,50%)

}

body{
 /* background: #0bbeb5; */
 /* background:  #483D8B*/
 /* background:  #9370DB */
 background: #c5c5d4;
}

h2{
/*   color:  #4a0ec3; */
color: rgb(3, 67, 23);
  text-decoration: none;
}

h4{
  color:  rgb(0,0,0);
  text-decoration: none;
}

h5{
  color:  #0b010e;
  text-decoration: none;
}

h1,p,a{
  color:  #011a1f;
  text-decoration: none;
   font-family: "Times New Roman", Times, serif; 
/* font-family:Verdana, Geneva, Tahoma, sans-serif */
}

ul{
  list-style-type: none;
}

.btn{
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
/*   background: rgb(248,217,15); */
background: rgb(6, 231, 235);
  color: #222;
  border: 1px solid #ffff;
  font-weight: 600;
  cursor: pointer;
}

.btn-light{
  background: transparent;
  color: #fff;
}

.btn:hover {
background: rgba(12, 104, 122, 0.2);
color: #04163f;
transition: 0.3s;
}