.hero {
    height: 100%;
    width: 100%; 
    background-color: #bed8ec ;
}

.mask{
    width: 100%;
    height: 75vh;
    position: relative;
}

.intro-img{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
object-fit: cover;

    filter:saturate(1) ;
    transition:brightness(1) ;
}

.mask:hover .intro-img{
  
    filter:invert(0.25) ;
    transition:brightness(0.50) ;  
}

.hero .content {
    position: absolute ;
    transform: translate(-50% , -50%) ; 
    top: 35%;
    left: 50%;
    text-align: center;

}

.hero .content h2 {
    font-size: 2.5rem;
    font-weight: 400;
    padding: 0.6rem 0 1rem;
    text-transform: uppercase;
}

.hero .content h4 {
    font-size: 1.5rem;
    font-weight: 400;
    padding: 0.6rem 0 1.5rem;
    
}


.contact_container{
    display: grid;
    place-items: center;
}

.contact_wrapper  {
width: fit-content;
height: fit-content;
display: flex;
align-items: center;
 }


 .contact_wrapper a {
    font-size: 10rem;
padding-left: 3rem;
padding-right: 3rem;
padding-top: 1rem;
padding-bottom: 1rem;
    color: blue;
    background-color: bisque;
    border: 5px solid rgb(81, 9, 248);
    border-radius: 1.5rem  ;
 
 }

 @media screen and (max-width: 740px) {
    .hero{
        max-width: 90%;
      margin: auto; 
        grid-template-columns: 1fr;
    }
    .mask{
        width: 90%;
        margin-inline: auto;

    }
    .content{

        grid-template-columns: 1fr;
        gap: 4rem;
    }


    .hero .content h2 {

        font-family: Verdana, Geneva, Tahoma, sans-serif;
        font-size: 1.2rem;
    
      font-weight: 400;  
      padding: 0 0 1rem;   
        text-transform: 
         uppercase;  
    }
    
    .hero .content h4 {
        font-size: 0.7rem;
        font-weight: 400;
         padding: 0  0 2rem; 
        
    }
}